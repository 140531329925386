/* eslint-disable react/prop-types */
import React from 'react';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';

import { KeyboardDateTimePicker } from '@material-ui/pickers';
const RenderDateField = ({
  input, meta: { touched, error }, helperText, ...custom
}) => {
  const helperTextLocal = typeof helperText === 'function' ? helperText(input) : helperText;
  const value = typeof input.value === 'number' ? fromUnixTime(input.value) : null;
  return (
    <KeyboardDateTimePicker
      margin="normal"
      error={!!touched && !!error}
      name={input.name}
      views={['year', 'month', 'date']}
      value={value}
      format="dd/MM/yyyy HH:mm"
      onChange={date => input.onChange(getUnixTime(date))}
      helperText={helperTextLocal}
      {...custom}
    />
  );
};

export default RenderDateField;
