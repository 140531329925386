import React from 'react';
import { Field, Fields } from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import RenderTextField from 'components/Form/TextField';
import RenderRichTextField from 'components/Form/RichTextField';
import RenderFileField from 'components/Form/FileField';
import RenderDateField from 'components/Form/DateField';
import RenderCheckField from 'components/Form/CheckField';
import RenderSelectField from 'components/Form/SelectField';
import { formatFromUnix, slugify } from 'helpers/utils';
const styles ={
textGray:{
 color: '#0000008a',
 fontFamily:'Roboto',
 fontWeight:300
}
}
const RenderName = (props) => {
  const {
    station, id, name, slug, publicAt, classes,
  } = props;
  const url = id.input.value && `${station.input.value.get('baseUrl')}${id.input.value}/${publicAt.input.value ? slug.input.value : slugify(name.input.value)}`;
  return (
    <Field
      label="Nombre del formulario"
      name="name"
      margin="normal"
      fullWidth
      component={RenderTextField}
      helperText={(
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.hdLink}
        >
          {url}
        </a>
      )}
    />
  );
};
export default class EditConfig extends React.PureComponent  {

  componentDidMount=()=>{
  const {formFormat , params} = this.props
  if (params.formId==='new') {
  let valor =  params.formFormat
  formFormat.input.onChange(valor);
  }
  }
  render(){
    const {
      handleShowMessage, params, classes, thankText,tmpFiles, nameForm, stateApp, stations, /* stateUser, author, */
    } = this.props;
    const stationsOption = stations.map((station) => {
      const selectStation = stateApp.getIn(['stations']).find(stationApp => stationApp.get('id') === station.get('value'));
      return {
        label: selectStation.get('name'),
        value: selectStation.get('id'),
        baseUrl: selectStation.get('baseUrl'),
      };
    }).toJS();
    return ( 
    <>
    <Grid item xs={12} className={classes.gridItemField}>
      <Fields
        label="Nombre del formulario"
        names={['name', 'id', 'station', 'slug', 'publicAt']}
        baseUrl="http://"
        margin="normal"
        classes={classes}
        component={RenderName}
      />
    </Grid>
    <Grid item xs={4} className={classes.gridItemField}>
      <Field
        label="Estación"
        name="station"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stationsOption,
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
    </Grid>
    <Grid item xs={4} className={classes.gridItemField}>
      <Field
        label="Restricción"
        name="restriction"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'restrictions']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
    </Grid>
    <Grid item xs={3} style={{display:'none'}}  className={classes.gridItemField}>
      <Field
        label="Formato"
        name="formFormat"
        id="format"
        margin="normal"
        params ={params}
        fullWidth
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={4} className={classes.gridItemField}>
      <Field
        label="Fecha de cierre"
        name="expireAt"
        margin="normal"
        placeholder="dd/mm/yyyy"
        fullWidth
        component={RenderDateField}
        helperText={input => (input.value ? `Cierre ${formatFromUnix(input.value, 'dd/MM/yyyy')} 23:59`: '')}
      />
    </Grid>
    <Grid item xs={6}>
    <Field
        label="Etiqueta"
        name="categoria"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'categories']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
    </Grid>
    <Grid item xs={6}>
    <Field
        label="Nivel de Compromiso"
        name="nivel"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'comp_level']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
      </Grid>
      <Grid item xs={6}>
    <Field
        label="Etiqueta II"
        name="categoria_two"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'categories']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
    </Grid>
    <Grid item xs={6}>
    <Field
        label="Nivel II"
        name="nivel_two"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'comp_level']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
      </Grid>
      <Grid item xs={6}>
    <Field
        label="Etiqueta III"
        name="categoria_three"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'categories']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
    </Grid>
    <Grid item xs={6}>
    <Field
        label="Nivel III"
        name="nivel_three"
        margin="normal"
        fullWidth
        renderValue={input => input.value.get('label')}
        componentProps={{
          isSearchable: false,
          options: stateApp.getIn(['types', 'comp_level']),
          noOptionsMessage: () => 'Vacío',
        }}
        component={RenderSelectField}
      />
      </Grid>
    <Grid item xs={6}>
      <Field
        label="Archivo: Terminos y condiciones"
        name="conditionsFileUrl"
        validFileTypes={['.pdf', '.png']}
        onError={handleShowMessage}
        component={RenderFileField}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        label="Archivo: Relación de ganadores"
        name="winnersFileUrl"
        validFileTypes={['.pdf', '.png']}
        onError={handleShowMessage}
        component={RenderFileField}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
      />
    </Grid>
    <Grid item xs={12} className={classes.gridItemField}>
      <Field
        label="Título"
        name="title"
        margin="normal"
        fullWidth
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={12} className={classes.gridItemField}>
      <Field
        label="Subtítulo"
        name="subtitle"
        margin="normal"
        fullWidth
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={12} className={classes.gridItemField}>
      <Field
        label="Descripción"
        name="description"
        margin="normal"
        fullWidth
        multiline
        rows="5"
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={12} className={classes.gridItemField}>
      <Field
      name="VthankText"
      style={styles}
      control = {thankText}
      label="Mensaje de Agradecimiento"
      component={RenderRichTextField}
      />
    </Grid>
    <Grid item xs={12} style={{display:'none'}}   className={classes.gridItemField}>
      <Field
        name="thankText"
        margin="normal"
        fullWidth
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
    <Field
        label="Hipervínculo Titulo"
        name="hiperlinkTitle"
        margin="normal"
        fullWidth
        component={RenderTextField}
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
    <Field
        label="Hipervínculo url"
        name="hiperlinkUrl"
        margin="normal"
        fullWidth
        component={RenderTextField}
        placeholder="http://www.forma.com.pe"
      />
    </Grid>
    <Grid item xs={12} className={classes.gridItemField}>
    <Field
        label="Certificado"
        name="cert"
        margin="normal"
        fullWidth
        component={RenderTextField}
        placeholder="http://www.forma.com.pe"
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
      <Field
        label="Publicado"
        name="isPublic"
        component={RenderCheckField}
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
    <Field
      label="Visible en Landing"
      name="isVisible"
      component={RenderCheckField}
    />
  </Grid>
    <Grid
      item
      xs={12}
      className={classes.gridItemField}
    >
      <Fields
        names={['name', 'id', 'station', 'slug', 'publicAt']}
        component={({
          station, id, name, publicAt, slug,
        }) => {
          const url = id.input.value && `${station.input.value.get('baseUrl')}${id.input.value}/${publicAt.input.value ? slug.input.value : slugify(name.input.value)}`;
          return (
            id.input.value ? (
              <div>
                <InputLabel>Embed</InputLabel>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  onClick={(event) => window.getSelection().selectAllChildren(event.target)}
                  className={classes.embed}
                >
                  {`<iframe title="embed" src="${url}" frameBorder="0" style="width:100%;min-height:1080px;height:100%;border:0"></iframe>`}
                </Typography>
              </div>
            ) : null
          );
        }}
      />
    </Grid>
  </>
);
}
}
